import { AxiosResponse } from 'axios';
import {
    Event,
    EventsSearchFilter,
    EventAPIType,
    EventPhotoSearchFilter,
    SimpleEvent,
    SimpleEventsSearchFilter,
    EventInfo
} from './types';
import {
    Page,
    PaginationResponse,
    PhotoPaginationResponse
} from 'src/api/base';
import httpClient from 'src/utils/http-сlient';
import queryString from 'query-string';
import { Photo } from 'src/api/photos/types';

const getEventsList = (
    page: Page,
    filter?: EventsSearchFilter
): Promise<AxiosResponse<PaginationResponse<Event>>> => {
    return httpClient.get<PaginationResponse<Event>>('/api/v2/events/', {
        params: {
            page: page.pageNumber,
            ...{ page_size: page.pageSize ? page.pageSize : 40 },
            ...(filter && { is_empty: filter.is_empty }),
            ...(filter?.location_id && { location_id: filter.location_id }),
            ...(filter?.year && { year: filter.year }),
            ...(filter?.file_id && { file_id: filter.file_id }),
            ...(filter?.search && { search: filter.search }),
            ...(filter?.event_id && { event_id: filter.event_id }),
            ...(filter?.person_id && { person_id: filter.person_id }),
            ...(filter?.photographer_id && {
                photographer_id: filter.photographer_id
            }),
            ...(filter?.date_after && { date_after: filter.date_after }),
            ...(filter?.date_before && { date_before: filter.date_before }),
            ...(filter?.file_id && { file_id: filter.file_id })
        },
        paramsSerializer(params) {
            return queryString.stringify(params, { arrayFormat: 'none' });
        }
    });
};

const getEventEventsList = (
    event_pk: number,
    page: Page,
    data?: EventsSearchFilter
): Promise<AxiosResponse<PaginationResponse<Event>>> => {
    return httpClient.get<PaginationResponse<Event>>(
        `/api/v2/events/${event_pk}/events/`,
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 40 },
                ...data
            }
        }
    );
};

const getEventEventsInfo = (event_pk: number, id: number): Promise<AxiosResponse<EventInfo>> => {
    return httpClient.get<EventInfo>(`/api/v2/events/${event_pk}/events/${id}`);
};

const getEventInfo = (id: number): Promise<AxiosResponse<EventInfo>> => {
    return httpClient.get<EventInfo>(`/api/v2/events/${id}/`);
};

const getEventPhotosList = (
    id: number,
    page: Page,
    filter?: EventPhotoSearchFilter
): Promise<AxiosResponse<PhotoPaginationResponse<Photo>>> => {
    return httpClient.get<PhotoPaginationResponse<Photo>>(
        `/api/v2/events/${id}/photos/`,
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 40 },
                ...filter
            },
            paramsSerializer(params) {
                return queryString.stringify(params, { arrayFormat: 'none' });
            }
        }
    );
};

const getSimpleEventsList = (
    page: Page,
    filter?: SimpleEventsSearchFilter
): Promise<AxiosResponse<PaginationResponse<SimpleEvent>>> => {
    return httpClient.get<PaginationResponse<SimpleEvent>>(
        `/api/v2/events/simple/`,
        {
            params: {
                page: page.pageNumber,
                ...{ page_size: page.pageSize ? page.pageSize : 40 },
                ...(filter?.location_id && { location_id: filter.location_id }),
                ...(filter?.year && { year: filter.year }),
                ...(filter?.search && { search: filter.search }),
                ...(filter?.person_id && { person_id: filter.person_id }),
                ...(filter?.photographer_id && {
                    photographer_id: filter.photographer_id
                }),
                ...(filter?.ordering && { ordering: filter.ordering }),
                ...(filter?.is_video && { is_video: filter.is_video })
            },
            paramsSerializer(params) {
                return queryString.stringify(params, { arrayFormat: 'none' });
            }
        }
    );
};

export default {
    getEventsList,
    getEventEventsList,
    getEventEventsInfo,
    getEventInfo,
    getEventPhotosList,
    getSimpleEventsList
} as EventAPIType;
