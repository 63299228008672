import React, {
    Fragment,
    MouseEvent,
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { useClassnames } from 'hook/use-classnames';
import { useCancelTokens } from 'component/core/cancel-token';
import UI from 'component/ui';
import Loader from 'component/loader';
import ErrorBlock from 'component/error';
import Form, { useRegistry } from 'component/form';
import { INormalizeObject } from 'component/helper/types/normalize-object';
import history from 'component/core/history';
import InputPhoto from 'component/form/input-photo';
import IconCross from 'component/icon/cross';
import useDidUpdateEffect from 'hook/use-did-update-effect';
import { DataPersonsItem } from 'component/api/types/api/main/get-person-list/get/code-200';
import {
    DataFilesTmpFacesItem,
    DataFilesTmpFacesPersonsItem
} from 'component/api/types/api/tmp-file/get-files-list/get/code-200';
import { parse, stringify } from 'query-string';
import { normalizeObject } from 'component/helper/normalize-object';
import useIntersect from 'hook/use-intersect';
import FilterForm from 'component/form/filter-form';
import PersonCarousel from 'component/person-carousel';
import CarouselItem from 'component/person-carousel/item';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers/types/reducers';
import { key as keyDeviceInfo } from 'store/reducers/deviceInfo/reducer';
import { key as keyUser } from 'store/reducers/user/reducer';
import style from './index.pcss';
import { EventStatistic } from 'src/api/statistics/types';
import api from 'src/api';
import { PersonItem, PersonsSearchFilter } from 'src/api/persons/types';
import { Photo } from 'src/api/photos/types';
import EventPhotoList from 'component/new-photo-list';
import { Page } from 'src/api/base';
import {
    EventInfo,
    EventPhotoSearchFilter,
    Event as EventItemType,
    EventsSearchFilter
} from 'src/api/events/types';
import { CreateTempFile } from 'src/api/files/types';
import Post from 'route/home/post';
import Button from 'component/button';

const getNormalizedQuery = () => {
    const qs = parse(location.search);

    return normalizeObject(qs);
};

const PHOTOS_LIMIT = 50;

const EVENTS_LIMIT = 10;

const LazyImageComponent = React.lazy(() => import('./image'));

const Event = () => {
    const cn = useClassnames(style);
    const { t } = useTranslation();
    const [token, photosToken, tokenFile, tokenPersons] = useCancelTokens(4);

    const isMobile = useSelector<IStore, boolean>(
        (store) => store[keyDeviceInfo].mobile
    );
    const isTablet = useSelector<IStore, boolean>(
        (store) => store[keyDeviceInfo].tablet
    );
    const isAdmin = useSelector<IStore, boolean | undefined>(
        (store) => store[keyUser].is_admin
    );
    const { id }: { id?: string } = useParams();
    const registry = useRegistry();

    const [isQueryChecked, setIsQueryChecked] = useState<boolean>(false);
    const [validity, setValidity] = useState<boolean>(false);
    const [persons, setPersons] = useState<Array<DataPersonsItem>>([]);
    const [queryParams, setQueryParams] = useState<INormalizeObject>(
        getNormalizedQuery()
    );
    const [eventInfo, setEventInfo] = useState<EventInfo>();
    const [pending, setPending] = useState<boolean>(true);
    const [pendingFile, setPendingFile] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [image, setImage] = useState<CreateTempFile | null>(null);
    const [chosenPersonIds, setChosenPersonIds] = useState<Array<number>>([]);

    const [eventStat, setEventStat] = useState<EventStatistic | null>(null);

    const [photosList, setPhotosList] = useState<Array<Photo>>([]);
    const [photosTotal, setPhotosTotal] = useState<number>(0);
    const [photosPage, setPhotosPage] = useState<number>(1);
    const [isPhotosPending, setIsPhotosPending] = useState<boolean>(false);
    const [isPhotosMerge, setIsPhotosMerge] = useState<boolean>(false);
    const [isPhotosNext, setIsPhotosNext] = useState<boolean>(false);
    const [personsList, setPersonsList] = useState<Array<PersonItem>>([]);
    const [personsTotal, setPersonsTotal] = useState<number>(0);

    const [isPersonsLoadMore, setIsPersonsLoadMore] = useState<boolean>(false);
    const [isPersonsPending, setIsPersonsPending] = useState<boolean>(false);
    const [isPersonsMerge, setIsPersonsMerge] = useState<boolean>(false);
    const [isPersonsNext, setIsPersonsNext] = useState<boolean>(false);
    const [personsPage, setPersonsPage] = useState<number>(1);
    const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);

    // new sub events
    const [isEventListPending, setIsEventListPending] = useState<boolean>(
        false
    );
    const [isEventListLoadMore, setIsEventListLoadMore] = useState<boolean>(
        false
    );
    const [eventListPage, setEventListPage] = useState<number>(1);
    const [isEventListNext, setIsEventListNext] = useState<boolean>(true);
    const [eventList, setEventList] = useState<Array<EventItemType>>([]);
    const [eventListTotal, setEventListTotal] = useState<number>(0);

    useEffect(() => {
        if (isEventListPending || isEventListLoadMore) {
            const page: Page = {
                pageNumber: eventListPage,
                pageSize: EVENTS_LIMIT
            };

            const data: EventsSearchFilter = {
                is_empty: false
            };

            api.events
                .getEventEventsList(Number(id), page, data)
                .then((resp) => {
                    setEventListTotal(resp.data.count);
                    setEventList(
                        isEventListLoadMore
                            ? [...eventList, ...resp.data.results]
                            : resp.data.results
                    );
                    setIsEventListPending(false);
                    setIsEventListLoadMore(false);
                    setIsEventListNext(!!resp.data.next);
                })
                .catch(() => {
                    setIsEventListPending(false);
                    setIsEventListLoadMore(false);
                    setIsEventListNext(false);
                });
        }
    }, [isEventListPending, isEventListLoadMore]);

    useEffect(() => {
        if (queryParams.file_id) {
            setIsLoadingImage(true);
            api.files
                .getTempFile(queryParams.file_id)
                .then((resp) => {
                    const href = URL.createObjectURL(resp.data);

                    const newImage = {
                        id: queryParams.file_id,
                        url: href
                    };

                    setImage(newImage);
                    setIsLoadingImage(false);
                })
                .catch(() => {
                    setIsLoadingImage(false);
                });
        }
    }, []);

    const getPersonsLimit = (): number => {
        if (isMobile) {
            return 4;
        }

        if (isTablet) {
            return 6;
        }

        return 10;
    };

    useEffect(() => {
        if (isPersonsPending || isPersonsLoadMore) {
            const page: Page = {
                pageNumber: personsPage,
                pageSize: getPersonsLimit()
            };

            const filter: PersonsSearchFilter = {
                event_id: Number(id),
                ...(queryParams.search && { search: queryParams.search }),
                ...(queryParams.is_person_find_partner && {
                    is_person_find_partner: queryParams.is_person_find_partner
                }),
                ...(queryParams.number && { number_id: queryParams.number }),
                ...(queryParams.file_id && { file_id: queryParams.file_id })
            };

            api.persons
                .getPersonsList(page, filter)
                .then((resp) => {
                    setPersonsList(
                        isPersonsMerge
                            ? [...personsList, ...resp.data.results]
                            : resp.data.results
                    );
                    setPersonsTotal(resp.data.count);
                    setIsPersonsNext(!Boolean(resp.data.next === null));
                })
                .finally(() => {
                    setIsPersonsPending(false);
                    setIsPersonsLoadMore(false);
                })
                .catch(() => {
                    setIsPersonsNext(false);
                    setIsPersonsPending(false);
                    setIsPersonsLoadMore(false);
                    setPersonsList([]);
                    setPersonsTotal(0);
                });
        }
    }, [isPersonsPending, isPersonsLoadMore]);

    useEffect(() => {
        api.statistic.getEventStatistics(Number(id)).then((resp) => {
            setEventStat(resp.data);
        });
    }, []);

    useEffect(() => {
        setQueryParams(getNormalizedQuery());
    }, [location.search]);

    useEffect(() => {
        _request();
        setIsEventListPending(true);
    }, []);

    useEffect(() => {
        return () => {
            photosToken.remove();
            token.remove();
            tokenFile.remove();
        };
    }, []);

    useDidUpdateEffect(() => {
        onSubmitForm();
    }, [JSON.stringify(chosenPersonIds)]);

    useEffect(() => {
        if (isPhotosPending) {
            const page: Page = {
                pageNumber: photosPage,
                pageSize: PHOTOS_LIMIT
            };

            const filter: EventPhotoSearchFilter = {
                ...(queryParams.color_id && { color_id: queryParams.color_id }),
                ...(queryParams.search && { search: queryParams.search }),
                ...(queryParams.number && { number: queryParams.number }),
                ...(queryParams.is_person_find_partner && {
                    is_person_find_partner: queryParams.is_person_find_partner
                }),
                ...(queryParams.time_from && {
                    time_after: queryParams.time_from
                }),
                ...(queryParams.time_to && {
                    time_before: queryParams.time_to
                }),
                ...(queryParams.event_date_from && {
                    date_after: queryParams.event_date_from
                }),
                ...(queryParams.event_date_to && {
                    date_before: queryParams.event_date_to
                }),
                ...(queryParams.person_id && {
                    person_id: queryParams.person_id
                }),
                ...(queryParams.is_stock && { is_stock: queryParams.is_stock }),
                ...(queryParams.photographer_id && {
                    photographer_id: queryParams.photographer_id
                }),
                ...(queryParams.is_sell_suit && {
                    is_sell_suit: queryParams.is_sell_suit
                }),
                ...(queryParams.file_id && { file_id: queryParams.file_id })
            };

            api.events
                .getEventPhotosList(Number(id), page, filter)
                .then((resp) => {
                    setIsPhotosNext(!Boolean(resp.data.next === null));
                    setPhotosList(
                        isPhotosMerge
                            ? [...photosList, ...resp.data.results]
                            : resp.data.results
                    );
                    setPhotosTotal(resp.data.count);
                })
                .finally(() => {
                    setIsPhotosPending(false);
                })
                .catch(() => {
                    setIsPhotosNext(false);
                    setIsPhotosPending(false);
                });
        }
    }, [isPhotosPending]);

    const onClickMoreEvents = (e: React.MouseEvent): void => {
        e.preventDefault();

        if (isEventListNext) {
            setEventListPage((prev) => prev + 1);
            setIsEventListLoadMore(true);
        }
    };

    const _request = () => {
        if (id) {
            setPending(true);

            api.events
                .getEventInfo(Number(id))
                .then((resp) => {
                    setEventInfo(resp.data);
                    setPending(false);
                })
                .catch((err) => {
                    if (!axios.isCancel(err)) {
                        console.error(err);

                        setError(err.message);
                        setPending(false);
                    }
                });
        }
    };

    const onFileLoaded = (file: CreateTempFile): void => {
        setImage(file);

        onSubmitForm();
    };

    const choosePersons = (personId: number) => {
        const index = chosenPersonIds.indexOf(personId);
        let newList = [...chosenPersonIds];

        if (index > -1) {
            newList = newList.filter((item) => item !== personId);
        } else {
            newList.push(personId);
        }

        setChosenPersonIds(newList);
    };

    const onClickPerson = (personId: number) => {
        choosePersons(personId);
    };

    const onChangeForm = debounce(
        useCallback(() => {
            const payload = registry.form.getPayload();
            const faces = payload.photo?.tmp_faces;
            let personsFaces: Array<DataFilesTmpFacesPersonsItem> = [];

            if (faces?.length) {
                personsFaces = faces.reduce(
                    (
                        acc: Array<DataFilesTmpFacesPersonsItem>,
                        curr: DataFilesTmpFacesItem
                    ) => {
                        if (curr.persons?.length) {
                            curr.persons.forEach((person) => {
                                const isExist = acc.find(
                                    (item) => item.id === person.id
                                );

                                if (person.id && !isExist) {
                                    acc.push(person);
                                }
                            });
                        }

                        return acc;
                    },
                    []
                );
            }

            setPersons(personsFaces);

            let person_id: Array<number> = [];
            if (personsFaces.length) {
                person_id = chosenPersonIds.length
                    ? chosenPersonIds.map((person) => person)
                    : personsFaces.map((item) => item.id);
            } else if (personsList.length && chosenPersonIds.length) {
                person_id = chosenPersonIds.map((person) => person);
            } else if (queryParams.person_id && !isQueryChecked) {
                person_id = queryParams.person_id;
                if (typeof queryParams.person_id === 'string') {
                    setChosenPersonIds([Number(queryParams.person_id)]);
                } else {
                    const chosenPersons = queryParams.person_id.map(
                        (item: string) => {
                            return Number(item);
                        }
                    );
                    setChosenPersonIds(chosenPersons);
                }
            }
            setIsQueryChecked(true);

            const data = {
                ...(queryParams.search && { search: queryParams.search }),
                ...(person_id.length && { person_id }),
                ...(payload.photo && { file_id: payload.photo.id }),
                ...(queryParams.location_id && {
                    location_id: queryParams.location_id
                }),
                ...(queryParams.location_name && {
                    location_name: queryParams.location_name
                }),
                ...(queryParams.event_date_from && {
                    event_date_from: queryParams.event_date_from
                }),
                ...(queryParams.event_date_to && {
                    event_date_to: queryParams.event_date_to
                }),
                ...(queryParams.color_id && { color_id: queryParams.color_id }),
                ...(queryParams.number && { number: queryParams.number }),
                ...(queryParams.time && { time: queryParams.time }),
                ...(queryParams.is_person_find_partner && {
                    is_person_find_partner: queryParams.is_person_find_partner
                }),
                ...(queryParams.is_stock && { is_stock: queryParams.is_stock }),
                ...(queryParams.suit_sale && {
                    suit_sale: queryParams.suit_sale
                }),
                ...(queryParams.time_from && {
                    time_from: queryParams.time_from
                }),
                ...(queryParams.time_to && { time_to: queryParams.time_to }),
                ...(queryParams.is_sell_suit && {
                    is_sell_suit: queryParams.is_sell_suit
                }),
                ...(queryParams.photographer_id && {
                    photographer_id: queryParams.photographer_id
                })
            };

            history.replace({
                search: stringify(data, {
                    arrayFormat: 'none'
                }),
                state: {
                    noScroll: true
                }
            });

            setIsPhotosNext(true);
            setIsPhotosMerge(false);
            setPhotosPage(1);
            setIsPhotosPending(true);
        }, [
            JSON.stringify(chosenPersonIds),
            validity,
            JSON.stringify(image),
            JSON.stringify(registry.form.getPayload()),
            JSON.stringify(persons),
            JSON.stringify(personsList)
        ]),
        300
    );

    const onSubmitForm = debounce(
        useCallback(() => {
            const payload = registry.form.getPayload();

            let person_id: Array<number> = [];
            if (personsList.length && chosenPersonIds.length) {
                person_id = chosenPersonIds.map((person) => person);
            } else if (queryParams.person_id && !isQueryChecked) {
                person_id = queryParams.person_id;
                if (typeof queryParams.person_id === 'string') {
                    setChosenPersonIds([Number(queryParams.person_id)]);
                } else {
                    const chosenPersons = queryParams.person_id.map(
                        (item: string) => {
                            return Number(item);
                        }
                    );
                    setChosenPersonIds(chosenPersons);
                }
            }
            setIsQueryChecked(true);

            const data = {
                ...(payload.search && { search: payload.search }),
                ...(person_id.length && { person_id }),
                ...(payload.photo && { file_id: payload.photo.id }),
                ...(payload.name && { search: payload.name }),
                ...(payload.location && {
                    location_id: payload.location.value
                }),
                ...(payload.location && {
                    location_name: payload.location.label
                }),
                ...(payload.club?.value && { club_id: payload.club.value }),
                ...(payload.club?.label && { club_name: payload.club.label }),
                ...(payload.event_date_range?.date_from && {
                    event_date_from: payload.event_date_range.date_from
                }),
                ...(payload.event_date_range?.date_to && {
                    event_date_to: payload.event_date_range.date_to
                }),
                ...(payload.color && { color_id: payload.color }),
                ...(payload.sport_number && { number: payload.sport_number }),
                ...(payload.time && { time: payload.time }),
                ...(payload.partner_search && {
                    is_person_find_partner: payload.partner_search
                }),
                ...(payload.is_stock && { is_stock: payload.is_stock }),
                ...(payload.suit_sale && { suit_sale: payload.suit_sale }),
                ...(payload.timeRange?.valueFrom && {
                    time_from: payload.timeRange.valueFrom
                }),
                ...(payload.timeRange?.valueTo && {
                    time_to: payload.timeRange.valueTo
                }),
                ...(payload.is_sell_suit && {
                    is_sell_suit: payload.is_sell_suit
                }),
                ...(queryParams.photographer_id && {
                    photographer_id: queryParams.photographer_id
                })
            };

            history.replace({
                search: stringify(data, {
                    arrayFormat: 'none'
                }),
                state: {
                    noScroll: true
                }
            });

            setIsPhotosNext(true);
            setPhotosPage(1);
            setIsPhotosMerge(false);
            setIsPhotosPending(true);

            setChosenPersonIds([]);
            setIsPersonsNext(true);
            setPersonsPage(1);
            setIsPersonsMerge(false);
            setIsPersonsPending(true);
        }, []),
        300
    );

    const onReset = useCallback(() => {
        setChosenPersonIds([]);
        registry.form.clearForm();
        setTimeout(() => {
            onSubmitForm();
        }, 300);
    }, []);

    const onClickRemove = useCallback((e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        setChosenPersonIds([]);
        registry.form.clearForm();
        setImage(null);
    }, []);

    const $bottomPreviousPosts = useIntersect(
        (entry) => {
            if (entry.isIntersecting && isPhotosNext) {
                setIsPhotosMerge(true);
                setPhotosPage((prevState) => prevState + 1);
                setIsPhotosPending(true);
            }
        },
        {
            rootMargin: '500px 0px'
        }
    );

    const elButtonBeforePosts = () => {
        if (!isPhotosPending && photosList.length && isPhotosNext) {
            if (!(photosList.length === photosTotal)) {
                return <Loader ref={$bottomPreviousPosts} />;
            }
        }
    };

    const elImageResult = useMemo(() => {
        if (image) {
            return (
                <Suspense
                    fallback={(
                        <div>
                            <Loader text="" />
                        </div>
                    )}
                >
                    <LazyImageComponent url={image.url} />
                </Suspense>
            );
        }
    }, [image]);

    const elUploadContent = useMemo(() => {
        if (isLoadingImage) {
            return <Loader />;
        }

        return (
            <Fragment>
                {elImageResult}
                <InputPhoto
                    defaultValue={image || undefined}
                    onFileLoaded={onFileLoaded}
                    registry={registry.field}
                    name="photo"
                />
                {image?.url && (
                    <div className={cn('event__photo-menu')}>
                        <div
                            onClick={onClickRemove}
                            className={cn('event__photo-menu-item')}
                        >
                            <IconCross
                                className={cn('event__photo-menu-icon')}
                            />
                            Сбросить поиск
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }, [JSON.stringify(image), isLoadingImage]);

    const elPhotos = useMemo(() => {
        if (photosList?.length) {
            const query_data = { ...parse(location.search) };
            const query = stringify(query_data, { arrayFormat: 'none' });
            const link = `/events/${id}`;

            return (
                <EventPhotoList
                    className={cn('event__photo-list')}
                    elIcons={false}
                    elControls={false}
                    elPortal={false}
                    list={photosList}
                    link={link}
                    query={`?${query}`}
                />
            );
        }

        return (
            <span className={cn('event__empty')}>
                {t('route.event.content.empty')}
            </span>
        );
    }, [JSON.stringify(photosList)]);

    const elContent = useMemo(() => {
        if (isPhotosPending && photosList.length === 0) {
            return <Loader className={cn('event__loader')} />;
        }

        return (
            <Fragment>
                <div className={cn('event__content-photo')}>
                    {elPhotos}
                    {elError}
                    {elButtonBeforePosts()}
                </div>
            </Fragment>
        );
    }, [JSON.stringify(photosList), error, isPhotosPending]);

    const elError = useMemo(() => {
        if (error) {
            return (
                <ErrorBlock className={cn('event__error')}>{error}</ErrorBlock>
            );
        }
    }, [error]);

    const elEventDate = useMemo(() => {
        if (eventInfo?.date) {
            return moment(eventInfo?.date).format('LL');
        }
    }, [eventInfo?.date]);

    const elEventLocation = useMemo(() => {
        if (eventInfo?.location) {
            const locationText = eventInfo.location.name;

            return locationText;
        }
    }, [eventInfo?.location]);

    const elPageHeader = useMemo(() => {
        if (eventInfo?.name) {
            const separate = !elEventLocation || !elEventDate ? '' : ', ';

            return (
                <div className={cn('event__header-wrapper')}>
                    <h1 className={cn('event__header')}>{eventInfo.name}</h1>
                    <div className={cn('event__subheader')}>
                        {eventStat && (
                            <div
                                className={cn(
                                    'event__subheader-count',
                                    'event__subheader-margin'
                                )}
                            >
                                {eventStat.photo_count > 0 && (
                                    <div>
                                        Фотографий: {eventStat.photo_count}
                                    </div>
                                )}
                                {eventStat.looking_partner_count > 0 && (
                                    <div>
                                        В поисках партнера:{' '}
                                        {eventStat.looking_partner_count}
                                    </div>
                                )}
                                {eventStat.suit_count > 0 && (
                                    <div>
                                        Костюмов в продаже:{' '}
                                        {eventStat.suit_count}
                                    </div>
                                )}
                                {isAdmin && (
                                    <div>
                                        На модерации:{' '}
                                        {eventStat.unverified_photo_count}
                                    </div>
                                )}
                            </div>
                        )}
                        <div
                            className={cn(
                                'event__subheader-event',
                                'event__subheader-margin'
                            )}
                        >
                            {elEventLocation}
                            {separate}
                            {elEventDate}
                        </div>
                    </div>
                </div>
            );
        }
    }, [eventInfo, eventStat, isAdmin]);

    const onClickNext = useCallback(() => {
        if (isPersonsNext && !isPersonsPending && !isPersonsLoadMore) {
            setPersonsPage((prevState) => prevState + 1);
            setIsPersonsMerge(true);
            setIsPersonsLoadMore(true);
        }
    }, [isPersonsNext, isPersonsPending, isPersonsLoadMore]);

    const elPersonCarousel = useMemo(() => {
        if (isPersonsPending) {
            return <Loader />;
        }

        if (personsList.length) {
            return (
                <PersonCarousel
                    onClickNext={onClickNext}
                    isLoading={isPersonsLoadMore}
                >
                    {personsList.map((item, index) => {
                        const params = {
                            item: {
                                id: item.id,
                                name:
                                    item.first_name && item.last_name
                                        ? item.full_name
                                        : item.id.toString(),
                                photo_url: item.photo,
                                is_partner_profile: item.is_partner_profile
                            },
                            onClick: () => onClickPerson(item.id),
                            isSelected: chosenPersonIds.indexOf(item.id) > -1
                        };

                        return <CarouselItem key={index} {...params} />;
                    })}
                </PersonCarousel>
            );
        } else {
            return (
                <UI.Box className={cn('event__box')} padding={true}>
                    <div className={cn('search__persons-content')}>
                        Ничего не найдено
                    </div>
                </UI.Box>
            );
        }
    }, [
        isPersonsPending,
        isPersonsLoadMore,
        JSON.stringify(personsList),
        JSON.stringify(chosenPersonIds)
    ]);

    const elButtonBeforeEvents = () => {
        if (isEventListLoadMore) {
            return <Loader />;
        }

        if (
            !isEventListPending &&
            !isEventListLoadMore &&
            eventList?.length &&
            isEventListNext
        ) {
            if (!(eventList?.length - 1 === eventListTotal)) {
                return (
                    <Button
                        disabled={isEventListPending}
                        isLoading={isEventListPending || isEventListLoadMore}
                        isSecondary={true}
                        onClick={onClickMoreEvents}
                    >
                        Загрузить ещё
                    </Button>
                );
            }
        }
    };

    const elBottomContent = useMemo(() => {
        if (isEventListPending && !eventList.length) {
            return <Loader />;
        }

        if (eventList.length) {
            return (
                <Fragment>
                    <UI.PageHeader text="Вложенные события" />
                    <div className={cn('event-sub__items')}>
                        {eventList.map((item, index) => (
                            <Post
                                sell_suit_count={item.sell_suit_count}
                                key={index}
                                name={item.name}
                                id={item.id}
                                photo_count={item.photo_count}
                                photos={item.photos}
                                location_name={item.location_name}
                                date={item.date}
                                className={cn('event-sub__post')}
                                isBlankLink={true}
                            />
                        ))}
                    </div>
                </Fragment>
            );
        }
    }, [JSON.stringify(eventList), isEventListPending]);

    if (pendingFile) {
        return (
            <UI.Main className={cn('event__loader')}>
                <Loader />
            </UI.Main>
        );
    }

    return (
        <UI.Main className={cn('event')}>
            {elPageHeader}
            <div className={cn('event-sub')}>
                {elBottomContent}
                {elButtonBeforeEvents()}
            </div>
            <div className={cn('event__grid')}>
                <div>
                    {personsTotal > 0 && (
                        <>
                            <UI.BoxHeader className={cn('event__title')}>
                                Участники события
                            </UI.BoxHeader>
                            <div className={cn('event__person-amount')}>
                                Общее количество персон: {personsTotal}
                            </div>
                            <UI.Box
                                padding={false}
                                className={cn('event__slider')}
                            >
                                {elPersonCarousel}
                            </UI.Box>
                        </>
                    )}
                    <UI.BoxHeader className={cn('event__title')}>
                        Фотографии
                    </UI.BoxHeader>
                    <UI.Box padding={true} className={cn('event__content')}>
                        {elContent}
                    </UI.Box>
                </div>
                <div className={cn('event__sidebar')}>
                    <UI.Box padding={true} className={cn('event__box')}>
                        <UI.BoxHeader>Поиск по фото</UI.BoxHeader>
                        <Form registry={registry.form}>{elUploadContent}</Form>
                    </UI.Box>
                    <UI.Box padding={false} className={cn('event__box')}>
                        <FilterForm
                            registry={registry}
                            color={true}
                            personNumber={true}
                            timeRange={true}
                            search={true}
                            searchPrompt={true}
                            eventDate={true}
                            partnerSearch={true}
                            suitSale={true}
                            stockPhotos={true}
                            onChangeValidity={setValidity}
                            onReset={onReset}
                            onSubmitForm={onSubmitForm}
                            isSearchSubmit={false}
                        />
                    </UI.Box>
                </div>
            </div>
        </UI.Main>
    );
};
// tslint:disable-next-line max-file-line-count
export default Event;
